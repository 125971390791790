import * as React from 'react'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { useCallback, useState } from 'react'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway, ViewType } from 'react-images'
import * as styles from '../styles/o-nas.module.scss'
import '../styles/photo-gallery.scss'

import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import PageContent from '../components/PageContent/PageContent'

interface ImageSharpEdge {
  node: {
    childImageSharp: {
      thumb: IGatsbyImageData
      full: IGatsbyImageData
    }
  }
}

interface PageProps {
  data: {
    images: {
      edges: ImageSharpEdge[]
    }
  }
}

function ONasPage({ data }: PageProps) {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  // function ONasPage() {
  const images = data.images.edges.map(({ node }, index) => ({
    src: node.childImageSharp.full.images.fallback?.src,
    srcSet: node.childImageSharp.full.images.sources[0].srcSet,
    width: node.childImageSharp.full.width,
    height: node.childImageSharp.full.height,
    sizes: node.childImageSharp.full.images.sources[0].sizes,
    caption: `Zdjęcie ${index}`
  }))

  const openLightbox = useCallback((_event: never, { index }: never) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  // eslint-disable-next-line no-console
  console.log(images)

  return (
    <Layout>
      <SEO title="O nas" description="Informacje o salonie" />
      <div
        className={classNames(
          'h-screen relative max-w-[100vw] overflow-x-hidden',
          styles.container,
          styles.container__background
        )}
      >
        <PageContent title="O NAS">
          <p className="mb-5">
            Jestem profesjonalistką, posiadam dyplomy oraz certyfikat Master
            Groomer, certyfikowany przez prezesa European Grooming Association
            Umberto Lehmanna. Wiele miesięcy uczyłam się u boku doskonałej w tej
            dziedzinie Tatiany Rzeszutek - właścicielki salonu Ambernova oraz
            Ambernova Grooming Academy oraz posiadaczki 5-ciu tytułów Master
            Groomer. Doskonaliłam swój warsztat także pod opieką innych
            wspaniałych groomerów takich jak Klaudia Zielińska czy Izabela
            Krupska, które także posiadają tytuły Master Groomer.
          </p>
          <p className="mb-5">
            Cały czas doskonalę swoją wiedzę oraz umiejętności poprzez
            szkolenia, kursy, literaturę. Śledzę także wszystkie najnowsze
            trendy.
          </p>
          <p className="mb-5">
            Dysponuję najnowszym i wysokiej jakości sprzętem groomerskim, do
            pielęgnacji używam wyłącznie profesjonalnych, sprawdzonych
            kosmetyków najwyższej jakości.
          </p>
          <p className="mb-5">
            Do każdego psa podchodzę indywidualnie, poprzez rozmowę z
            właścicielem staram się dobrać odpowiednią fryzurę, która podkreśli
            atuty psa, a zarazem spełni wszelkie oczekiwania właściciela. Poza
            pięknym wyglądem każdy pies poddany jest zabiegom pielęgnacyjnym,
            takim jak: obcinanie pazurów, czyszczenie uszu, kosmetyka opuszków.
          </p>
          <p>
            Od nastoletnich lat chciałam zostać psim behawiorystą i wiedza
            zebrana przez te wszystkie lata dotycząca psychiki psa oraz jego
            zachowań ułatwia mi kontakt z psem oraz sprawia, iż wizyta w moim
            salonie nie jest dla niego stresująca i przebiega w spokojnej
            atmosferze. Najważniejsze jest dobre samopoczucie psa, dlatego
            strzyżenie i wszelkie zabiegi pielęgnacyjne wykonywane są bez użycia
            jakichkolwiek środków uspokajających.
          </p>
          <div className="mt-4">
            <Gallery photos={images} onClick={openLightbox} />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={
                      images.map(x => ({
                        ...x
                      })) as unknown as ViewType[]
                    }
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        </PageContent>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default ONasPage
