import * as React from 'react'
import classNames from 'classnames'
import * as styles from './PageContent.module.scss'

type PageContentProps = {
  title: string
  children: React.ReactNode
}

function PageContent({ title, children }: PageContentProps) {
  return (
    <article className="text-gold font-sans text-base mt-[6rem] sm:mt-0 p-5 sm:p-[5rem] md:p-[8rem]">
      <h1 className="text-center text-3xl">{title}</h1>
      <div className={classNames('mt-[2rem] p-5', styles.content)}>
        {children}
      </div>
    </article>
  )
}

export default PageContent
